<template>
  <div :class="{ open: open }" class="modal">
    <div id="modal-wrapper" class="modal-wrapper" :key="task.status">
      <form action="" class="modal-form-wrapper">
        <div class="modal-form-text-part">
          <i class="material-icons modal-close" @click="closeModal()">close</i>
          <p class="modal-title">{{ task.title }}</p>
          <div class="task-complexity">
            <div v-if="task.complexity !== null">
              <label>Сложность материала: </label>
              <progress
                id="progressBar"
                max="100"
                :value="task.complexity"
              ></progress>
            </div>
          </div>
          <div v-if="task.status !== 'BLOCKED'" class="modal-info">
            {{ task.description }}
          </div>
          <div v-else class="modal-info">
            Данный урок не может быть просмотрен. Вам необходимо
            <a href="/tariffs">оплатить подписку</a>.
          </div>
          <div class="modal-general-info-wrapper">
            <div v-html="task.article" />
          </div>
        </div>

        <div
          class="modal-rating-wrapper"
          v-bind:class="{ 'active-background': task.status === 'FAILED' }"
        >
          <div class="modal-rating-input" v-if="task.status !== 'BLOCKED'">
            <input
              id="modal-check-input"
              v-model="task.completed"
              disabled
              hidden
              title="check"
              type="checkbox"
            />
            <label class="checkbox" for="modal-check-input"> </label>

            <span v-if="task.status !== 'SKIPPED'" class="modal-input-title"
              >Завершена</span
            >
            <span v-if="task.status === 'SKIPPED'" class="modal-input-title"
              >Пропущена</span
            >
          </div>

          <Rating v-if="open && task.status !== 'BLOCKED'" :task="task" />
        </div>

        <div class="modal-form-text-part">
          <div v-if="task.note" class="modal-task-info">
            {{ task.note }}
          </div>

          <StepList v-if="open" :task="task" />

          <div class="modal-form-btn-wrapper">
            <button
              class="modal-form-btn cancel"
              type="reset"
              @click="closeModal()"
            >
              Закрыть окно
            </button>
            <button
              v-if="!task.completed && task.status !== 'BLOCKED'"
              class="modal-form-btn submit"
              type="reset"
              @click="skipTask(task.generatedTaskId)"
            >
              Завершить текущую задачу
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import StepList from "../components/StepList.vue";
import Rating from "../components/Rating.vue";

import axios from "axios";
import { UtilsService } from "@/services";

export default {
  data() {
    return {
      open: false,
      pooling: null
    };
  },
  props: {
    data: {}
  },
  components: {
    StepList,
    Rating
  },
  async mounted() {
    await this.$store
      .dispatch("LOAD_SUBSCRIPTION")
      .catch(res => {
        if (res.status === 403) {
          if (this.data.status === "BLOCKED") {
            this.$store
              .dispatch("SET_TASK", {
                task: {
                  title: this.data.title,
                  status: this.data.status,
                  complexity: this.data.complexity
                }
              })
              .then(() => this.showModal());
            return;
          }
        }
      })
      .then(this.$store.dispatch("FETCH_USER"));
    if (this.data.status === "BLOCKED") {
      this.$store
        .dispatch("SET_TASK", {
          task: {
            title: this.data.title,
            status: this.data.status,
            complexity: this.data.complexity
          }
        })
        .then(() => this.showModal());
      return;
    }

    this.$store
      .dispatch("LOAD_TASK", { task: this.data })
      .then(() => this.showModal())
      .then(() => this.updateStatus());
  },
  unmounted() {
    clearInterval(this.polling);
  },
  updated() {
    UtilsService.showSpoilers();
    UtilsService.updateLinks();
    UtilsService.initHighlighting();
  },
  computed: {
    task() {
      return this.$store.getters.TASK;
    }
  },
  methods: {
    closeModal() {
      document.body.classList.remove("body-active");
      this.open = false;
      this.$emit("modal-close");
    },
    showModal() {
      document.body.classList.add("body-active");
      this.open = true;
    },
    updateStatus() {
      this.polling = setInterval(() => {
        this.$store.dispatch("UPDATE_USER_TASK_STATE", { task: this.task });
      }, 2000);
    },
    skipTask(taskId) {
      axios
        .post(
          process.env.VUE_APP_BACKEND_ROOT_URL +
            "/user-tasks/" +
            taskId +
            "/skip"
        )
        .then(() =>
          this.$store.dispatch("UPDATE_USER_TASK_STATE", { task: this.task })
        )
        .then(() => this.closeModal());
    }
  }
};
</script>

<style lang="scss" scoped>
@import "/public/template/scss/modal.scss";
</style>
