<template>
  <div class="rating-block">
    <p class="rating-title">Оцените данную задачу</p>
    <div class="o-container">
      <ul
        id="js-rating-star"
        class="c-rating-star u-text-center"
        data-name="test"
      >
        <VueStars
          :active-color="'orange'"
          :class="'rating-star-icon'"
          :hover-color="'orange'"
          :inactive-char="'★'"
          :inactive-color="'#fff'"
          :shadow-color="'#fff'"
          :value="task.rating ? task.rating : 0"
          v-on:v-star-input="sendRating($event)"
        />
      </ul>
    </div>
    <p class="common-rating-title">Общий рейтинг задачи</p>
    <p class="common-rating-title">
      Участников проголосовало: {{ getSumRating.peopleVoices }}
    </p>
    <div class="o-container">
      <ul
        id="js-common-rating-star"
        class="c-rating-star u-text-center"
        data-name="test"
      >
        <VueStars
          :name="'commonRating'"
          :readonly="true"
          :active-color="'orange'"
          :class="'rating-star-icon'"
          :hover-color="'orange'"
          :inactive-char="'★'"
          :value="
            getSumRating.commonRating ? Math.ceil(getSumRating.commonRating) : 0
          "
        />
      </ul>
    </div>
    <div v-if="showFeedbackTextBox">
      <p class="alert alert-info">
        Спасибо! <br />
        Желаете оставить дополнительную информацию?
      </p>
      <textarea v-model="feedback" class="form-control"></textarea>
      <br />
      <button
        v-on:click.prevent="sendFeedback"
        class="btn btn-outline-info btn-sm"
      >
        Отправить
      </button>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import VueStars from "@/components/VueStars";
import { NotificationService } from "@/services";

export default {
  components: { VueStars },
  props: {
    task: {}
  },
  data() {
    return {
      showFeedbackTextBox: false,
      feedback: ""
    };
  },
  mounted() {
    this.$store.dispatch("LOAD_COMMON_RATING", { task: this.task });
  },
  computed: {
    getSumRating() {
      return this.$store.getters.COMMON_RATING;
    },
    user() {
      return this.$store.getters.USER;
    }
  },
  methods: {
    sendRating(rating) {
      axios
        .post(
          process.env.VUE_APP_BACKEND_ROOT_URL +
            "/user-tasks/" +
            this.task.generatedTaskId +
            "/rating/" +
            rating
        )
        .then(() => {
          this.$store.dispatch("UPDATE_RATING", {
            task: this.task,
            rating: rating
          });
        })
        .then(() => {
          this.$store.dispatch("LOAD_COMMON_RATING", {
            task: this.task
          });
        });
      this.showFeedbackTextBox = true;
    },
    sendFeedback() {
      const feedback = `Пользователь оставил отзыв к заданию ${this.task.title}(${this.task.id}): ${this.feedback}`;

      axios
        .post(process.env.VUE_APP_BACKEND_ROOT_URL + "/feedback", {
          name: this.user.username,
          contact: this.user.email ? this.user.email : "email неизвестен",
          review: feedback
        })
        .then(() =>
          NotificationService.success("Ваш отзыв успешно отправлен! Спасибо!")
        );
    }
  }
};
</script>

<style lang="scss" scoped>
@import "/public/template/scss/rating.scss";
</style>
