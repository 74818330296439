<template>
  <main class="courses-module-wrapper">
    <section class="spring-knowledge">
      <div class="spring-knowledge-wrapper">
        <p class="spring-knowledge-title">{{ module.title }}</p>
        <p class="spring-knowledge-info" v-html="module.description"></p>
      </div>
    </section>

    <TaskList
      v-if="loadTasks"
      v-bind:module="module"
      :key="taskListIdx"
      @modal-close="modalClosed()"
    />
  </main>
</template>

<script>
import TaskList from "../components/TaskList.vue";

import axios from "axios";

export default {
  data() {
    return {
      module: {},
      loadTasks: false,
      taskListIdx: 0
    };
  },
  components: {
    TaskList
  },
  methods: {
    modalClosed() {
      this.taskListIdx += 1;
    }
  },
  mounted() {
    axios
      .get(
        process.env.VUE_APP_BACKEND_ROOT_URL +
          "/modules?search=code==" +
          this.$route.params.code
      )
      .then(this.$store.dispatch("FETCH_USER"))
      .then(response => {
        this.module = response.data[0];
        this.loadTasks = true;
      });
  }
};
</script>

<style lang="scss" scoped>
@import "/public/template/scss/module.scss";
</style>
