<template>
  <div
    v-if="(steps !== undefined && steps.length > 0) || task.note != null"
    class="step-list-component"
  >
    <!-- TODO should be dinamic -->
    <template
      v-if="
        (this.$route.params.code !== 'database-basic' &&
          this.$route.params.code !== 'last-step') ||
          task.title === 'Финальное задание. Подключаем БД к боту'
      "
    >
      <p class="modal-general-questions-info modal-general-questions">
        Скопируйте идентификатор задачи в Intellij Plugin и загрузите это
        задание к себе:
      </p>
      <div class="modal-input-wrapper">
        <div class="modal-input-field-wrapper">
          <input
            :value="task.generatedTaskId"
            :placeholder="task.generatedTaskId"
            class="modal-input-field"
            disabled
            title="id"
            type="text"
          />
        </div>
        <div class="modal-input-btn-wrapper">
          <button
            v-clipboard:copy="task.generatedTaskId"
            v-clipboard:success="successCopy"
            class="modal-input-btn"
            @click.prevent
          >
            Копировать
          </button>
        </div>
      </div>
    </template>
    <template v-else>
      <p class="modal-general-questions-info modal-general-questions">
        Напишите необходимый код и нажмите на кнопку ниже:
      </p>
      <div class="modal-input-wrapper">
        <textarea
          v-model="query"
          class="form-control"
          id="exampleFormControlTextarea1"
          rows="3"
        ></textarea>
        <br />
        <h3 v-if="inProcess">Провераяем решение... Ожидайте.</h3>
        <button
          :disabled="inProcess === true"
          class="modal-input-btn"
          @click.prevent="sendSolution"
        >
          Отправить
        </button>
      </div>
    </template>

    <p class="modal-general-questions-how-is-it-work">
      <a
        v-if="this.$route.params.code !== 'database-basic'"
        href="https://youtu.be/Mw2rHxB4YRo"
        target="_blank"
        rel="noopener noreferrer"
      >
        Как это работает?</a
      >
      <a
        v-if="this.$route.params.code === 'database-basic'"
        href="https://youtu.be/M0Uup9Uns8s"
        target="_blank"
        rel="noopener noreferrer"
      >
        Как это работает?</a
      >
    </p>
    <div class="modal-check-input-check-container">
      <div
        v-for="(step, index) in steps"
        v-bind:key="index"
        class="modal-check-input-text-wrapper"
      >
        <div
          class="modal-checkbox-info-wrapper"
          v-bind:class="{ 'active-background': step.status === 'FAILED' }"
        >
          <div class="modal-check-input-wrapper">
            <input
              v-bind:id="'modal-check-' + index + '-input'"
              v-model="step.completed"
              disabled
              hidden
              title="check"
              type="checkbox"
            />
            <label v-bind:for="'modal-check-' + index + '-input'"></label>
            <span class="modal-check-input-title">{{ step.title }}</span>
            <p
              class="modal-check-input-failure-message"
              v-if="step.status === 'FAILED'"
            >
              {{ beautifyFailureMessage(step.failureMessage) }}
            </p>
          </div>
          <div class="modal-checkbox-svg" v-on:click="slideStep">
            <svg
              fill="none"
              height="18"
              viewBox="0 0 32 18"
              width="32"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2 2L16 16L30 2"
                stroke="white"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="4"
              />
            </svg>
          </div>
        </div>
        <p class="modal-check-info" v-html="step.description" />
      </div>
    </div>
  </div>
</template>

<script>
import { NotificationService, UtilsService } from "@/services";
import { default as jQuery } from "jquery";
import axios from "axios";

export default {
  name: "StepList",
  props: {
    task: {}
  },
  data: function() {
    return {
      query: "",
      processing: false,
      message: ""
    };
  },
  mounted() {
    this.$store.dispatch("LOAD_STEPS", { taskId: this.task.id });
  },
  updated() {
    UtilsService.updateLinks();
    UtilsService.showSpoilers();
  },
  computed: {
    steps() {
      return this.$store.getters.STEPS;
    },
    inProcess() {
      return this.processing;
    }
  },
  methods: {
    sendSolution() {
      if (this.query) {
        this.processing = true;
        const formData = new FormData();
        formData.append("file", new Blob([this.query], { type: "text/plain" }));
        axios
          .post(
            process.env.VUE_APP_PROCESSING_URL +
              "/" +
              this.task.generatedTaskId,
            formData,
            { timeout: 120000 }
          )
          .then(result => {
            this.processing = false;
            if (result.data.status === "UNCOMPLETED") {
              NotificationService.error(
                "Ошибка выполнения задачи. Обратите внимание на сообщение, его же вы найдёте в самом шаге: \n" +
                  result.data.message,
                { timeOut: "20000" }
              );
            }
          });
      }
    },
    beautifyFailureMessage(failureMessage) {
      if (failureMessage) {
        failureMessage = failureMessage.replace(
          "==> expected: ",
          "\n\nОжидалось: "
        );
        failureMessage = failureMessage.replace("but was:", "\nРезультат: ");
      }
      return failureMessage;
    },
    slideStep(event) {
      if (event) {
        let currentElement = jQuery(event.target).closest(
          ".modal-checkbox-svg"
        );
        if (currentElement) {
          currentElement
            .closest(".modal-check-input-text-wrapper")
            .find(".modal-check-info")
            .slideToggle();
          currentElement.toggleClass("active-svg");
        }
      }
    },
    successCopy(params) {
      NotificationService.success(
        "Идентификатор задачи (" +
          params.text +
          ") успешно скопирован в буфер обмена!"
      );
    }
  }
};
</script>

<style lang="scss" scoped>
@import "/public/template/scss/step-list.scss";
</style>
