<template>
  <section class="spring-knowledge-cart">
    <Modal
      v-if="modalVisible"
      @modal-close="closeModal()"
      :key="modalIdx"
      :data="modalData"
    />

    <div v-if="!loading">
      <div v-if="module.available" class="spring-knowledge-cart-wrapper">
        <div
          v-for="(task, index) in tasks"
          :key="index"
          class="spring-knowledge-cart-item-wrap"
        >
          <!-- final task should be marked read initially -->
          <template v-if="task.final && !task.status">
            <div class="red-cart-background">
              <p
                class="spring-knowledge-cart-finish-item-number white-cart-number"
              >
                {{ index + 1 }}
              </p>
              <div class="spring-knowledge-cart-item-img">
                <img alt="" src="/template/img/circle-img.svg" />
              </div>
              <p class="spring-knowledge-cart-finish-item-text white-cart-text">
                {{ task.title }}
              </p>
            </div>
          </template>

          <!-- templates by task statuses -->
          <template v-if="!task.final && !task.status">
            <div class="spring-knowledge-cart-item white-cart-background">
              <p class="spring-knowledge-cart-item-number">{{ index + 1 }}</p>
              <p class="spring-knowledge-cart-item-text">
                {{ task.title }}
              </p>
            </div>
          </template>

          <template
            v-if="task.status === 'READY' || task.status === 'IN_PROGRESS'"
          >
            <div class="spring-knowledge-cart-item orange-background-cart">
              <p class="spring-knowledge-cart-item-number">{{ index + 1 }}</p>
              <p class="spring-knowledge-cart-item-text">
                {{ task.title }}
              </p>
              <button
                class="spring-knowledge-cart-item-btn"
                @click="showModal(task)"
              >
                Продолжить
              </button>
              <div class="spring-knowledge-cart-image-wrapper">
                <img alt="" src="/template/img/boy-image.svg" />
              </div>
            </div>
          </template>

          <template v-if="task.status === 'FAILED'">
            <div class="spring-knowledge-cart-item red-failed-background-cart">
              <p class="spring-knowledge-cart-item-number">{{ index + 1 }}</p>
              <p class="spring-knowledge-cart-item-text">
                {{ task.title }}
              </p>
              <button
                class="spring-knowledge-cart-item-btn"
                @click="showModal(task)"
              >
                Продолжить
              </button>
              <div class="spring-knowledge-cart-image-wrapper">
                <img alt="" src="/template/img/boy-image.svg" />
              </div>
            </div>
          </template>

          <template v-if="task.status === 'SKIPPED'">
            <div class="spring-knowledge-cart-item skipped-background-cart">
              <p class="spring-knowledge-cart-item-number">{{ index + 1 }}</p>
              <p class="spring-knowledge-cart-item-text">
                {{ task.title }}
              </p>
              <button
                class="spring-knowledge-cart-item-btn"
                @click="showModal(task)"
              >
                Продолжить
              </button>
            </div>
          </template>

          <template v-if="task.status === 'COMPLETED'">
            <div class="spring-knowledge-cart-item blue-background-cart">
              <div class="cart-wrapper">
                <p class="spring-knowledge-cart-item-number">{{ index + 1 }}</p>
                <div class="spring-knowledge-cart-item-svg">
                  <svg
                    fill="none"
                    height="19"
                    viewBox="0 0 24 19"
                    width="24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M2 8.13889L8.90909 15L22 2"
                      stroke="white"
                      stroke-width="5"
                    />
                  </svg>
                </div>
              </div>
              <p class="spring-knowledge-cart-item-info">
                {{ task.title }}
              </p>
              <button
                class="spring-knowledge-cart-item-btn"
                @click="showModal(task)"
              >
                Посмотреть
              </button>
            </div>
          </template>

          <template v-if="task.status === 'BLOCKED'">
            <div class="spring-knowledge-cart-item blocked-background-cart">
              <p class="spring-knowledge-cart-item-number">{{ index + 1 }}</p>
              <p class="spring-knowledge-cart-item-info">
                {{ task.title }}
              </p>
              <button
                class="spring-knowledge-cart-item-btn"
                @click="showModal(task)"
              >
                Посмотреть
              </button>
            </div>
          </template>
        </div>
      </div>
      <div v-else class="spring-knowledge-cart-wrapper">
        <h2>
          Mодуль "{{ module.name }}" находится в разработке, приносим свои
          извинения.
        </h2>
      </div>
    </div>
  </section>
</template>

<script>
import Modal from "../components/Modal.vue";
import { NotificationService } from "@/services";

export default {
  name: "TaskList",
  props: ["module"],
  data() {
    return {
      loading: true,
      modalVisible: false,
      modalData: {},
      modalIdx: 0
    };
  },
  components: {
    Modal
  },
  methods: {
    showModal(task) {
      this.modalVisible = true;
      this.modalData = task;
    },
    closeModal() {
      if (this.$route.params.taskLink) {
        this.$router.push("/module/" + this.$route.params.code);
      }
      this.modalIdx++;
      this.modalVisible = false;
      this.$emit("modal-close");
    }
  },
  computed: {
    tasks() {
      return this.$store.getters.TASKS;
    }
  },
  mounted() {
    this.$store
      .dispatch("LOAD_TASKS", { moduleId: this.$route.params.code })
      .then(() => (this.loading = false))
      .then(() => {
        if (this.$route.params.taskLink) {
          let expectedTask = this.$store.getters.TASKS.find(
            t =>
              decodeURIComponent(t.link) ===
              decodeURIComponent(this.$route.params.taskLink)
          );
          if (expectedTask) {
            this.showModal(expectedTask);
          } else {
            NotificationService.error(
              "Задача по данному пути " +
                this.$route.params.taskLink +
                " не найдена."
            );
          }
        }
      });
  }
};
</script>

<style lang="scss" scoped>
@import "/public/template/scss/task-list.scss";
</style>
